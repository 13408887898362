/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.search-page-filter {
  width: 300px;
  box-sizing: border-box;
  padding: 0;
  height: 100%;
  overflow: hidden;
  border-left: 1px solid #eee;
}
.search-page-filter:hover {
  overflow: auto;
}
.search-page-filter :global .all-radio-style .ant-radio-wrapper {
  margin-bottom: 8px;
}
.search-page-filter :global .no-input-style {
  margin-bottom: -14px;
}
.search-page-filter :global .ant-radio-group {
  width: 100%;
}
.search-page-filter :global .ant-radio-wrapper {
  display: flex;
  align-items: center;
  border-radius: 6px;
  color: #222A35;
  width: 100%;
}
.search-page-filter :global .ant-radio-wrapper > span:last-child {
  width: 100%;
}
.search-page-filter :global .ant-radio-wrapper:last-child {
  margin-bottom: 0;
}
.search-page-filter :global .ant-radio-group {
  width: 100%;
}
.search-page-filter :global .ant-radio {
  top: 0;
}
.search-page-filter .filter-item:first-child {
  padding-top: 16px;
}
.search-page-filter .filter-item {
  padding: 24px 16px 0 16px;
}
.search-page-filter .filter-item .tag-title-content {
  margin-bottom: 4px;
}
.search-page-filter .filter-item .filter-item-title {
  font-weight: 500;
}
.search-page-filter .filter-item > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222A35;
  font-weight: 500;
  margin-bottom: 8px;
}
.search-page-filter .filter-item .title-has-reset {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2px;
}
.search-page-filter .filter-item .title-has-reset .reset {
  color: #047FFE;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
}
.search-page-filter .filter-item:last-child {
  border-bottom: none;
}
.search-page-filter .filter-item .radio-item {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 6px;
}
.search-page-filter .filter-item .radio-item:last-child {
  margin-bottom: 0;
}
.search-page-filter .filter-item .radio-item-with-input :global .ant-radio-wrapper {
  width: auto;
}
.search-page-filter .title-has-reset > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222A35;
  font-weight: 500;
  margin-bottom: 8px;
}
.search-page-filter .knowledge-name {
  width: 160px;
  height: 22px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.search-page-filter .knowledge-name span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}
.search-page-filter .knowledge-name .shaixuanIcon {
  font-size: 12px;
  margin: 0 3px;
  color: #212223;
}
.search-page-filter .moreDk {
  transition: all 0.2s;
  display: block;
}
.search-page-filter .moreDk.isOpen {
  transform: rotate(180deg);
}
.dk-switch-search-popver :global .ant-tooltip-content {
  transform: translate(50px, 0);
}
.hidden {
  display: none;
}
.noShow {
  visibility: hidden;
}
.attach {
  margin-top: 8px;
  color: rgba(34, 42, 53, 0.3);
}
.attach .text {
  color: rgba(34, 42, 53, 0.7);
}
.attach :global .checkbox-icon {
  width: 13px;
  height: 13px;
  position: relative;
  bottom: 1px;
}
.attach :global .ant-checkbox {
  transform: scale(0.875);
}
