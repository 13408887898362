/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.multi-choose-wrap :global .ant-checkbox-group {
  width: 100%;
  margin-bottom: 8px;
}
.multi-choose-wrap :global .ant-checkbox-group .ant-checkbox-wrapper {
  flex: 1;
  overflow: hidden;
}
.multi-choose-wrap :global .ant-checkbox-group .ant-checkbox + span {
  display: inline-flex;
  overflow: hidden;
}
.multi-choose-wrap .options-content .options-item {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 26px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multi-choose-wrap .options-content .options-item .item-content-wrap {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: baseline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multi-choose-wrap .options-content .options-item .item-content-wrap .image-content {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.multi-choose-wrap .options-content .options-item .item-content-wrap .image {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  position: relative;
  top: 4px;
}
.multi-choose-wrap .options-content .options-item .item-content-wrap .text {
  flex: 1;
  font-size: 14px;
  line-height: 24px;
  color: #222A35;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multi-choose-wrap .options-content .options-item .close-options-icon {
  margin-left: 10px;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: none;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.multi-choose-wrap .options-content .options-item .close-options-icon .remove-icon {
  font-size: 10px;
  line-height: 10px;
  color: rgba(34, 42, 53, 0.4);
}
.multi-choose-wrap .options-content .options-item .close-options-icon:hover {
  background-color: rgba(34, 42, 53, 0.1);
}
.multi-choose-wrap .options-content .options-item .close-options-icon:hover .remove-icon {
  color: rgba(34, 42, 53, 0.8);
}
.multi-choose-wrap .options-content .options-item:hover .close-options-icon {
  display: flex;
}
