/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.group-content-wrap {
  margin-bottom: 8px;
}
.group-content-wrap .all-scope-item {
  border-bottom: 1px solid rgba(34, 42, 53, 0.06);
  padding-bottom: 4px;
}
.group-content-wrap .all-scope-item:nth-child(1) {
  margin-bottom: 4px;
}
