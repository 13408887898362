/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.template-modal-content .preview-item {
  height: 395px;
  overflow-y: scroll;
  z-index: 1;
  text-align: center;
  padding-top: 40px;
  background: #F8F8F8;
  border-bottom: 1px solid #EFF1F3;
  border-top: 1px solid #EFF1F3;
}
.template-modal-content .preview-item .preview-img {
  width: 634px;
  height: auto;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
}
@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
.template-modal-content .loading-div {
  text-align: center;
  color: #bbb;
  font-size: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
}
.template-modal-content .loading-div img {
  width: 16px;
  vertical-align: middle;
  margin-right: 6px;
  position: relative;
  top: -1px;
  animation: swiper-preloader-spin 1s infinite linear;
}
.template-modal-content .swipe-bottom-btn {
  background: #ffffff;
  padding: 24px;
  display: flex;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  font-size: 14px;
  line-height: 20px;
}
.template-modal-content .swipe-bottom-btn .swipe-button-wrap {
  flex: 1;
}
.template-modal-content .swipe-bottom-btn .swipe-button-wrap .swipe-button {
  background: none;
  line-height: 20px;
  padding: 7px 24px;
  color: #333333;
  border: 1px solid #E6E6E6;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}
.template-modal-content .swipe-bottom-btn .swipe-button-wrap .swipe-button:focus {
  outline: none;
}
.template-modal-content .swipe-bottom-btn .swipe-button-wrap .swipe-button-prev {
  margin-right: 12px;
}
.template-modal-content .swipe-bottom-btn .swipe-button-wrap .grey-btn {
  border: 1px solid #E6E6E6;
  color: #999999;
}
.template-modal-content .swipe-bottom-btn .use-template {
  background: #000000;
  border: 1px solid #212224;
  border-radius: 3px;
  padding: 7px 10px;
  color: #ffffff;
  height: auto;
  cursor: pointer;
}
.template-modal-content .swipe-bottom-btn .use-template:focus {
  outline: none;
}
