/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.template-modal-wrap {
  color: #333333;
}
.template-modal-wrap .ant-modal-close {
  top: 4px;
}
.template-modal-wrap .ant-modal-close-x {
  position: absolute;
  top: 12px;
  right: 12px;
}
.template-modal-wrap .ant-modal-close-x::before {
  background-size: 20px 20px;
}
.template-modal-wrap .ant-modal-header {
  padding: 32px 32px 0px 32px;
  border-bottom: none;
  border-radius: 8px;
}
.template-modal-wrap .ant-modal-header .ant-modal-title .title-container {
  display: flex;
  align-items: center;
}
.template-modal-wrap .ant-modal-header .ant-modal-title .title-container .icon-arrow-up {
  width: 18px;
  height: 25px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 4px;
  cursor: pointer;
}
.template-modal-wrap .ant-modal-header .ant-modal-title .title-container p {
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  font-weight: 600;
}
.template-modal-wrap .ant-modal-header .ant-modal-title .title-container-style {
  margin-bottom: 14px;
}
.template-modal-wrap .ant-modal-body {
  padding: 0;
}
.template-modal-wrap .ant-modal-content {
  border-radius: 8px;
}
.template-modal-wrap .hidden {
  display: none;
}
