// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




@color-primary: #1A75FF;

.template-modal-content{
  .modal-title{
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 11px;
    padding-bottom: 8px;
    .modal-title-item{
      margin-right: 72px;
      text-align: center;
      color: #BBBBBB;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      .item-line{
        width: 24px;
        height: 2px;
        border-radius: 1px;
        margin-top: 4px;
      }
      .item-checked{
        background-color: @color-primary;
      }
    }
    .modal-title-item:last-child{
      margin-right: 0;
    }
    .modal-title-item-right{
      margin-right: 0;
    }
    .item-text-checked{
      color: #333333;
     
    }
  }
  .template-modal-main{
    display: flex;
    height: 443px;
    background: #F8F8F8;
    border-top:1px solid #EFF1F3;
    border-radius: 8px;
    .template-type-container{
      background: #ffffff;
      border-bottom-left-radius: 8px;
      .template-type-item{
        width: 134px;
        padding: 10px;
        text-align: center;
        color: #333330;
        cursor: pointer;
        .template-name{
          font-size: 14px;
          line-height: 24px;
        }
      }
      .template-type-item-active{
        color: @color-primary;
        font-weight: 600;
        background: rgba(26, 117, 255, 0.08);
        
      }
    }
    .template-list-container{
      border-left: 1px solid #E6E6E6;
      background: #F8F8F8;
      padding: 20px 0 20px 20px;
      overflow-y: scroll;
      overflow-x: hidden;
      border-bottom-right-radius: 8px;
      // margin-right: 8px;
      flex: 1;
      .one-template-type-list{
        .one-template-type-title{
          font-size: 16px;
          line-height: 22px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .one-template-type-main{
          display: flex;
          flex-wrap: wrap;
          .one-template-container{
            &:nth-child(4n) {
              margin-right: 0;
            }
            margin-bottom: 22px;
            margin-right: 20px;
            text-align: center;
            width: 116px;
            .template-img-container{
              position: relative;
              padding: 10px;
              border: 0.5px solid #E8E8E8;
              border-radius: 4px;
              background-color: #ffffff;
              cursor: pointer;
                &:hover{
                box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.1);
                .hover-container{
                  transform: scale(1.05, 1.05) ;
                  transition-duration: 0.3s;
                }
                .hover-button-wrap{
                  opacity: 1;
                }
              }
              .hover-container{
                font-size: 0;
               
                .hover-container-img{
                  width:100%;
                  height: 79px;
                  object-fit: cover;
                }
                .hover-container-doc{
                  height: 120px;
                }
              }
              
              .hover-button-wrap{
                opacity: 0;
                background: #ffffff;
                position: absolute;
                bottom: 8px;
                left: 0;
                z-index: 1;
                width: 100%;
                height: calc(100% - 16px);
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.57) 22%, #FFFFFF 99%);
                .btn-hover{
                  width: 84px;
                  height: 24px;
                  border: 1px solid rgba(0, 0, 0, 0.15);
                  border-radius: 3px;
                  background-color: #fff;
                  cursor: pointer;
                  outline: none;
                }
                .btn-hover-use{
                  margin-top: 4px;
                  background-color: #333333;
                  color: #FFFFFF;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }

            .template-name{
              color: #666666;
              line-height: 17px;
              font-size: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 8px 0 0 0;
            }
          }
        }
      }
    }

  }
  .template-moddal-iframe {
    padding: 20px;

    iframe {
      width: 660px;
      height: 100%;
      border: none;
    }
  }
  .empty-wrap{
    background: #F8F8F8;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-left: -20px;
    .empty-img{
      width: 70px;
      height: 70px;
    }
    .empty-title{
      margin-top: 24px;
      color: #BBBBBB;
      font-size: 12px;
      line-height: 17px;
    }
  }
}