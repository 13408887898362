/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0 4px 14px;
  cursor: pointer;
}
.title-wrap:hover .more {
  color: #047FFE;
}
.title-wrap .name {
  font-size: 14px;
  line-height: 22px;
  color: #222A35;
  font-weight: 500;
}
.title-wrap .more {
  font-size: 12px;
  line-height: 20px;
  color: rgba(34, 42, 53, 0.5);
  display: flex;
  align-items: center;
}
.title-wrap .more i {
  font-size: 12px;
  position: relative;
  top: 1px;
}
