/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-member-selector :global .ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.cooper-member-selector :global .ant-select .member-tag {
  display: flex;
  align-items: center;
  height: 24px;
  line-height: 24px;
  color: #2F343C;
  background: #f3f3f3;
  border-radius: 4px;
  border: none;
  margin-top: 2px;
  margin-bottom: 2px;
}
.cooper-member-selector :global .ant-select .member-tag .member-tag-text {
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cooper-member-selector :global .ant-select .member-tag .anticon {
  font-size: 14px;
  vertical-align: middle;
  font-weight: bold;
  color: #bec5d2;
  padding-left: 4px;
}
.cooper-member-selector :global .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #e4e9f3;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.cooper-member-selector :global .ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}
.cooper-member-selector :global .ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.cooper-member-selector :global .ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selector {
  min-height: 36px !important;
  height: auto !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
}
.cooper-member-selector :global .ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}
.cooper-member-selector :global .ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  content: '\a0';
}
.cooper-member-selector :global .ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.cooper-member-selector :global .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
  -webkit-padding-end: 4px;
  padding-inline-end: 4px;
}
.cooper-member-selector :global .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #e4e9f3;
  cursor: not-allowed;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  -webkit-margin-start: 3px;
  margin-inline-start: 3px;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-search-input,
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 24px;
  transition: all 0.3s;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-search-input {
  font-size: 14px;
  width: 100%;
  min-width: 4.1px;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.cooper-member-selector :global .ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 8px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.cooper-member-selector :global .ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px;
}
.cooper-member-selector :global .ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}
.cooper-member-selector :global .ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}
.cooper-member-selector :global .ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.cooper-member-selector :global .ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}
.cooper-member-selector :global .ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px;
}
.cooper-member-selector :global .ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}
.cooper-member-selector :global .ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}
.cooper-member-selector :global .ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.cooper-member-selector :global .ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}
.cooper-member-selector :global .ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}
.cooper-member-selector :global .ant-select-multiple.ant-select-sm .ant-select-selection-search {
  -webkit-margin-start: 3px;
  margin-inline-start: 3px;
}
.cooper-member-selector :global .ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px;
}
.cooper-member-selector :global .ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}
.cooper-member-selector :global .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}
.cooper-member-selector :global .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}
.cooper-member-selector :global .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.cooper-member-selector :global .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.cooper-member-selector :global .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
}
.cooper-member-selector :global .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}
.cooper-member-selector :global .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.cooper-member-selector :global .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
}
.cooper-member-selector :global .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #0066FF;
  border-right-width: 1px !important;
}
.cooper-member-selector :global .ant-select-selection-item {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cooper-member-selector :global .ant-select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: #bec5d2;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.cooper-member-selector :global .ant-select-arrow {
  display: inline-block;
  font-style: normal;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 53%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.cooper-member-selector :global .ant-select-arrow > * {
  line-height: 1;
}
.cooper-member-selector :global .ant-select-arrow svg {
  display: inline-block;
}
.cooper-member-selector :global .ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}
