/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.c-tree {
  font-size: 14px;
  margin: 4px 0;
  padding: 2px 0;
  width: 336px;
  height: 250px;
  border-radius: 4px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(47, 52, 60, 0.2);
  position: absolute;
  right: 4px;
  z-index: 1;
  top: 30px;
}
.c-tree img {
  width: 18px;
  margin-right: 4px;
  vertical-align: middle;
}
.c-tree .triangle-container {
  cursor: pointer;
}
.c-tree .triangle-container > .triangle {
  display: inline-block;
  font-size: 14px;
  margin-right: 2px;
}
.c-tree .tree-node {
  height: 32px;
  line-height: 32px;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  user-select: none;
}
.c-tree .tree-node:hover {
  background-color: #f0f3f7;
}
.c-tree .tree-node.selected {
  background-color: rgba(0, 102, 255, 0.1);
}
.c-tree .tree-node.no-permis {
  opacity: 0.7;
  cursor: not-allowed;
  position: relative;
}
.c-tree .tree-node > span {
  vertical-align: middle;
}
.c-tree .tree-node .hl {
  color: #06f;
}
.c-tree > p {
  margin-top: 80px;
  text-align: center;
  color: #94a0b0;
}
