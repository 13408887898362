/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.search-history-title {
  font-size: 14px;
  line-height: 22px;
  color: rgba(34, 42, 53, 0.5);
  padding: 2px 0px 0px 12px;
}
