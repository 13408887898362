/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.global-search-wrap {
  height: 32px;
  line-height: 32px;
  width: 100%;
  position: relative;
}
.global-search-wrap .search-before {
  display: flex;
  align-items: center;
  background: none;
  height: 24px;
  line-height: 24px;
  color: rgba(34, 42, 53, 0.3);
  border: none;
  padding-left: 3px;
  font-size: 14px;
}
.global-search-wrap .search-tag {
  margin-left: 8px;
  padding: 0 4px 0 8px;
  border-radius: 3px;
  background: #EBEEF1;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
}
.global-search-wrap .search-tag > span > i {
  color: #333;
  font-size: 14px;
  cursor: pointer;
}
.global-search-wrap .search-tag:hover {
  background: #E5F2FE;
}
.global-search-wrap .search-tag:hover > span > i {
  color: rgba(34, 42, 53, 0.6);
}
.global-search-wrap .search-icon-btn {
  display: block;
  align-items: center;
  line-height: 14px;
  font-size: 12px;
  border: 1px solid rgba(34, 42, 53, 0.2);
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: 4px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.global-search-wrap .search-icon-btn:hover {
  background: #D8DEE3;
  border-color: transparent;
}
.global-search-wrap .search-icon-btn .search-text {
  margin-right: 5px;
}
.global-search-wrap :global .ant-input-affix-wrapper {
  height: 32px;
}
.global-search-wrap :global .ant-input-group-addon {
  border-radius: 4px;
}
.click-tip {
  padding-right: 12px;
}
.global-search-popover {
  width: 540px !important;
  max-width: 540px !important;
  z-index: 1069 !important;
  padding-top: 3px!important;
}
.global-search-popover .search-popover-content {
  margin: 0px 6px 10px 6px;
}
.global-search-popover .search-popover-content li {
  height: auto !important;
  margin-bottom: 0px!important;
  border: none!important;
  padding: 0!important;
}
.global-search-popover .search-popover-content li:hover {
  background-color: transparent !important;
}
.global-search-popover .sticky-content {
  position: sticky;
  top: 0;
  margin: 6px 10px 0px 10px;
}
.global-search-popover .search-more-wrap {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 8px;
}
.global-search-popover .search-more {
  cursor: pointer;
  color: rgba(34, 42, 53, 0.5);
  border-radius: 8px;
  padding: 8px 12px;
}
.global-search-popover .search-more .more-icon-wrap {
  background: #047FFE;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin-right: 9px;
}
.global-search-popover .search-more .more-icon-wrap i {
  color: #FFF;
  font-size: 14px;
}
.global-search-popover .search-more .more-b {
  font-weight: 500;
  color: rgba(34, 42, 53, 0.9);
  font-size: 14px;
  line-height: 22px;
}
.global-search-popover .search-more:hover {
  background: rgba(34, 42, 53, 0.04);
}
/** scrollbar for windows,mac and others**/
:global .mac .search-popover-content-os-flag,
:global .linux .search-popover-content-os-flag,
:global .generic .search-popover-content-os-flag {
  overflow: auto;
  overflow: overlay;
}
:global .windows .search-popover-content-os-flag {
  overflow: hidden;
}
:global .windows .search-popover-content-os-flag:hover {
  overflow: auto;
}
