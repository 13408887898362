/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.create {
  width: 390px;
}
.create .tenant-tips {
  width: 360px;
  height: auto;
  border-radius: 4px;
  opacity: 1;
  color: #1a6eff;
  background: rgba(26, 110, 255, 0.09);
  /* line-height: 27px; */
  text-align: start;
  padding-left: 6px;
  margin-bottom: 7px;
}
.create > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.create > div img {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 12px;
}
.create > div .create-li {
  cursor: pointer;
  border-radius: 4px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #222A35;
  position: relative;
}
.create > div .create-li:hover {
  background-color: #F2F3F3;
}
.create > div .create-li-new {
  background: #FF563B;
  border-radius: 2.75px;
  color: #ffffff;
  height: 12px;
  line-height: 11px;
  padding: 0 2px;
  font-size: 11px;
  position: absolute;
  top: 3px;
  left: 26px;
  transform: scale(0.9);
}
.create > div .create-li-beta {
  border-radius: 20px;
  background: #EBEEF1;
  color: #6A707C;
  height: 13px;
  line-height: 13px;
  padding: 0 4px;
  margin-left: 4px;
  font-size: 11px;
}
.create-folder .create-li {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.create-space {
  padding-top: 4px;
  border-bottom: 1px solid #F6F7F7;
}
.create-space .create-li {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.create-collab {
  margin-top: 4px;
  border-top: 1px solid #F6F7F7;
  padding-top: 4px;
}
.create-collab .create-li {
  width: 194px;
  height: 42px;
  display: flex;
  align-items: center;
}
.create-collab-old {
  margin-top: 4px;
  border-top: 1px solid #F6F7F7;
  padding-top: 4px;
}
.create-collab-old .create-li {
  width: 194px;
  height: 42px;
  display: flex;
  align-items: center;
}
.create-collab-old .create-li .old-tag {
  margin-left: 4px;
  font-family: PingFangSC-Medium;
  width: 32px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background-color: #EEEEEF;
  border-radius: 2px;
  font-size: 12px;
  color: #909499;
}
.create-import {
  margin-top: 4px;
  border-top: 1px solid #F6F7F7;
  padding-top: 4px;
}
.create-import .create-li {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.create-template {
  margin-top: 4px;
  border-top: 1px solid #F6F7F7;
  padding-top: 4px;
}
.create-template .create-li {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
