/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.person-card {
  display: flex;
  font-size: 14px;
  color: #17233e;
  align-items: center;
  padding: 9px;
}
.person-card img {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-right: 12px;
  vertical-align: middle;
}
.person-card .info {
  flex: 1;
}
.person-card .info .info-name-msg {
  max-width: 490px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  line-height: 14px;
}
.person-card .info .info-name-msg .name {
  display: inline-block;
  max-width: 94px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.person-card .info .info-name-msg .team-name {
  max-width: 382px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.person-card .info .info-name-msg .depart-text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}
.person-card .info .info-name-msg .exist-tag {
  background-color: rgba(138, 147, 168, 0.1);
  color: #8a93a8;
  font-size: 12px;
  line-height: 12px;
  border-radius: 4px;
  padding: 1px 4px;
}
.person-card .info .info-text-msg {
  margin-top: 6px;
  font-size: 12px;
  line-height: 10px;
  color: #8a93a8;
}
