/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.create-folder {
  font-size: 14px;
  color: #2f343c;
}
.create-folder .ant-input {
  width: 336px;
  height: 32px;
  font-size: 14px;
  color: #2f343c;
  border-color: rgba(148, 160, 176, 0.24);
}
.create-folder .ant-input:hover,
.create-folder .ant-input:focus {
  border-color: #06F;
  box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.2);
}
.create-folder .name,
.create-folder .path {
  position: relative;
  display: flex;
  align-items: center;
}
.create-folder .label {
  display: inline-block;
  width: 56px;
  margin-right: 20px;
  text-align: right;
  white-space: nowrap;
}
.create-folder .name {
  margin-top: 16px;
  margin-bottom: 24px;
}
.create-folder .path {
  margin-bottom: 36px;
  position: relative;
}
.create-folder .path img[alt=cancel],
.create-folder .path img[alt=down] {
  width: 24px;
  padding-left: 8px;
  background-color: #fff;
  position: absolute;
  right: 16px;
  top: 8px;
  cursor: pointer;
}
.create-folder .path img[alt=down] {
  width: 22px;
  top: 9px;
}
.create-folder .create-folder-error {
  font-size: 12px;
  color: #ff405b;
  position: absolute;
  left: 78px;
  top: 32px;
  margin: 0;
}
.create-folder-modal :global .ant-modal-content {
  border-radius: 8px;
}
