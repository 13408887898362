/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.menu-upload-li {
  height: 42px !important;
  margin-bottom: 8px !important;
}
.menu-upload-li:last-child {
  margin-bottom: 0 !important;
}
.menu-upload-li .upload-img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
