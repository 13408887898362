/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.member-selector-drop {
  height: 360px;
  /* 动画 */
}
.member-selector-drop .search-result-list .no-search-result {
  font-size: 14px;
  color: #999999;
  font-weight: 400;
  line-height: 280px;
  text-align: center;
}
.member-selector-drop .search-result-list .list-header {
  display: flex;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
  padding: 12px;
}
.member-selector-drop .search-result-list .list-header .title-item {
  margin-right: 40px;
}
.member-selector-drop .search-result-list .list-header .title-item .title-item-txt {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.member-selector-drop .search-result-list .list-header .title-item .title-item-txt:hover {
  color: #222A35;
}
.member-selector-drop .search-result-list .list-header .title-item .txt-checked {
  color: #333;
  font-weight: 500;
}
.member-selector-drop .search-result-list .list-header .title-item .txt-checked:hover {
  color: #222A35;
}
.member-selector-drop .search-result-list .list-header .title-item .title-check-flag {
  width: 24px;
  height: 2px;
  margin-top: 3px;
  background: #0066FF;
  border-radius: 1px;
  position: relative;
  left: calc(50% - 12px);
}
.member-selector-drop .search-result-list .user-tab {
  padding-bottom: 10px;
}
.member-selector-drop .search-result-list .result-content {
  height: 302px;
  overflow-y: auto;
  padding-bottom: 8px;
}
.member-selector-drop .search-result-list .result-content-tip {
  padding: 0 12px;
  line-height: 22px;
}
.member-selector-drop .search-result-list .result-content .person-card-wrap {
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}
.member-selector-drop .search-result-list .result-content .person-card-wrap :global .person-card .info .info-name-msg {
  max-width: 300px !important;
}
.member-selector-drop .search-result-list .result-content .person-card-wrap:hover {
  background: #F7F7F7;
}
.member-selector-drop .search-result-list .result-content .search-result-group {
  padding-bottom: 10px;
  border-bottom: 1px solid #E6E6E6;
  margin-bottom: 10px;
}
.member-selector-drop .search-result-list .result-content .search-result-group .group-title {
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  padding: 12px;
}
.member-selector-drop .search-result-list .result-content .search-result-group .group-title-tip {
  font-size: 12px;
  color: rgba(34, 42, 53, 0.5);
}
.member-selector-drop .search-result-list .result-content .search-result-group:first-child .group-title {
  padding-top: 0;
}
.member-selector-drop .search-result-list .result-content .search-result-group:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.member-selector-drop .more-member {
  padding: 7px;
  cursor: pointer;
  border-radius: 8px;
}
.member-selector-drop .more-member:hover {
  background: #F7F7F7;
}
.member-selector-drop .more-member .more-member-search {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.member-selector-drop .more-member .more-member-text {
  color: #999999;
  font-size: 12px;
  line-height: 12px;
  margin-right: 5px;
}
.member-selector-drop .more-member .arrow-right-grey {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
}
.member-selector-drop .more-member .arrow-right-grey::after {
  display: inline-block;
  content: " ";
  height: 6px;
  width: 6px;
  border-width: 1px 1px 0 0;
  border-color: #999999;
  border-style: solid;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
}
.member-selector-drop.ant-select-dropdown {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 0 8px;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.member-selector-drop.ant-select-dropdown-hidden {
  display: none;
}
.member-selector-drop.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}
.member-selector-drop.ant-move-down-enter,
.member-selector-drop.ant-move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.member-selector-drop.ant-slide-up-enter,
.member-selector-drop.ant-slide-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.member-selector-drop.ant-slide-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.member-selector-drop.ant-slide-up-enter.ant-slide-up-enter-active,
.member-selector-drop.ant-slide-up-appear.ant-slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.member-selector-drop.ant-slide-up-leave.ant-slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}
.member-selector-drop.ant-slide-up-enter,
.member-selector-drop.ant-slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.member-selector-drop.ant-slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.member-selector-drop.ant-slide-down-enter,
.member-selector-drop.ant-slide-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.member-selector-drop.ant-slide-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.member-selector-drop.ant-slide-down-enter.ant-slide-down-enter-active,
.member-selector-drop.ant-slide-down-appear.ant-slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.member-selector-drop.ant-slide-down-leave.ant-slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}
.member-selector-drop.ant-slide-down-enter,
.member-selector-drop.ant-slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.member-selector-drop.ant-slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
