// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.member-selector-drop{
  height: 360px;

  .search-result-list {
    .no-search-result {
      font-size: 14px;
      color: #999999;
      font-weight: 400;
      line-height: 280px;
      text-align: center;
    }

    .list-header{
      display: flex;
      text-align: center;
      background-color: #fff;
      cursor: pointer;
      padding: 12px;

      .title-item{
        margin-right: 40px;
        .title-item-txt{
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          &:hover {
            color: #222A35;
          }
        }
        .txt-checked {
          color: #333;
          font-weight: 500;
          &:hover {
            color: #222A35;
          }
        }

        .title-check-flag{
          width: 24px;
          height: 2px;
          margin-top: 3px;
          background: #0066FF;
          border-radius: 1px;
          position: relative;
          left: calc(50% - 12px);
        }
      }
    }

    .user-tab{
      padding-bottom: 10px;
    }

    .result-content{
      height: 302px;
      overflow-y: auto;
      padding-bottom: 8px;
      &-tip {
        padding: 0 12px;
        line-height: 22px;
      }
      .person-card-wrap{
        display: flex;
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
        :global {
          .person-card .info .info-name-msg {
            max-width: 300px !important;
          }
        }
        &:hover {
          background:  #F7F7F7;
        }
      }

      .search-result-group{
        padding-bottom: 10px;
        border-bottom: 1px solid #E6E6E6;
        margin-bottom: 10px;
        .group-title{
          font-size: 12px;
          line-height: 12px;
          color: #000000;
          padding: 12px;
          &-tip {
            font-size: 12px;
            color: @blueGray-6;
          }
        }
        &:first-child {
          .group-title{
            padding-top: 0;
          }
        }
        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }
  .more-member{
    padding: 7px;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background:  #F7F7F7;
    }

    .more-member-search{
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
    .more-member-text{
      color: #999999;
      font-size: 12px;
      line-height: 12px;
      margin-right: 5px;
    }
    .arrow-right-grey{
      display :inline-block;
      position: relative;
      width: 15px;
      height: 15px;
      &::after{
        display: inline-block;
        content: " ";
        height: 6px;
        width: 6px;
        border-width: 1px 1px 0 0;
        border-color: #999999;
        border-style: solid;
        transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
      }
    }
  }

  &.ant-select-dropdown {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    box-sizing: border-box;
    padding: 0 8px;
    overflow: hidden;
    font-size: 14px;
    font-variant: initial;
    background-color: #fff;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }

  &.ant-select-dropdown-hidden {
    display: none;
  }

  &.ant-select-dropdown-empty {
    color: rgba(0, 0, 0, 0.25);
  }

  /* 动画 */

  &.ant-move-down-enter,
  &.ant-move-down-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }

  &.ant-slide-up-enter,
  &.ant-slide-up-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }

  &.ant-slide-up-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }

  &.ant-slide-up-enter.ant-slide-up-enter-active,
  &.ant-slide-up-appear.ant-slide-up-appear-active {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }

  &.ant-slide-up-leave.ant-slide-up-leave-active {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }

  &.ant-slide-up-enter,
  &.ant-slide-up-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }

  &.ant-slide-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }

  &.ant-slide-down-enter,
  &.ant-slide-down-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }

  &.ant-slide-down-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }

  &.ant-slide-down-enter.ant-slide-down-enter-active,
  &.ant-slide-down-appear.ant-slide-down-appear-active {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }

  &.ant-slide-down-leave.ant-slide-down-leave-active {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }

  &.ant-slide-down-enter,
  &.ant-slide-down-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }

  &.ant-slide-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
}

