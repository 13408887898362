/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.tips-wrap {
  display: inline-flex;
  width: fit-content;
  align-items: baseline;
  padding: 4px 12px;
  align-items: auto;
  background-color: rgba(34, 42, 53, 0.04);
  color: #6A707C;
  border-radius: 4px;
  margin: 12px 0;
}
.tips-wrap .text {
  font-size: 12px;
  line-height: 20px;
}
.tips-wrap .remove-icon {
  font-size: 12px;
  transform: scale(0.65);
  margin-left: 1px;
}
.tips-wrap .remove-icon:hover {
  cursor: pointer;
}
