/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.help-link {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.help-tip {
  color: rgba(34, 42, 53, 0.3);
  font-size: 14px;
  line-height: 14px;
  margin-left: 4px;
  height: 14px;
}
.help-tip:hover {
  color: #222A35;
}
:global .cursor {
  cursor: pointer;
}
