// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.cooper-member-selector {
  :global {
    .ant-select {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: 'tnum';
      position: relative;
      display: inline-block;
      cursor: pointer;
      .member-tag {
        display: flex;
        align-items: center;
        height: 24px;
        line-height: 24px;
        color: #2F343C;
        background: #f3f3f3;
        border-radius: 4px;
        border: none;
        margin-top: 2px;
        margin-bottom: 2px;
        .member-tag-text {
          max-width: 320px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
  
        .anticon {
          font-size: 14px;
          vertical-align: middle;
          font-weight: bold;
          color: #bec5d2;
          padding-left: 4px;
        }
      }
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {

      position: relative;
      background-color: #fff;
      border: 1px solid #e4e9f3;
      border-radius: 4px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector input {
      cursor: pointer;
    }

    .ant-select-selection-overflow {
      position: relative;
      display: flex;
      flex: auto;
      flex-wrap: wrap;
      max-width: 100%;
    }

    .ant-select-selection-overflow-item {
      flex: none;
      align-self: center;
      max-width: 100%;
    }

    .ant-select-multiple .ant-select-selector {
      min-height: 36px !important;
      height: auto !important;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 1px 4px;
    }

    .ant-select-show-search.ant-select-multiple .ant-select-selector {
      cursor: text;
    }

    .ant-select-disabled.ant-select-multiple .ant-select-selector {
      background: #f5f5f5;
      cursor: not-allowed;
    }

    .ant-select-multiple .ant-select-selector::after {
      display: inline-block;
      width: 0;
      margin: 2px 0;
      line-height: 24px;
      content: '\a0';
    }

    .ant-select-multiple.ant-select-show-arrow .ant-select-selector,
    .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
      padding-right: 24px;
    }

    .ant-select-multiple .ant-select-selection-item {
      position: relative;
      display: flex;
      flex: none;
      box-sizing: border-box;
      max-width: 100%;
      height: 24px;
      margin-top: 2px;
      margin-bottom: 2px;
      line-height: 22px;
      background: #f5f5f5;
      border: 1px solid #f0f0f0;
      border-radius: 2px;
      cursor: default;
      transition: font-size 0.3s, line-height 0.3s, height 0.3s;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-margin-end: 4px;
      margin-inline-end: 4px;
      -webkit-padding-start: 8px;
      padding-inline-start: 8px;
      -webkit-padding-end: 4px;
      padding-inline-end: 4px;
    }

    .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
      color: #bfbfbf;
      border-color: #e4e9f3;
      cursor: not-allowed;
    }

    .ant-select-multiple .ant-select-selection-item-content {
      display: inline-block;
      margin-right: 4px;
      overflow: hidden;
      white-space: pre;
      text-overflow: ellipsis;
    }

    .ant-select-multiple .ant-select-selection-item-remove {
      color: inherit;
      font-style: normal;
      line-height: 0;
      text-align: center;
      text-transform: none;
      vertical-align: -0.125em;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      color: rgba(0, 0, 0, 0.45);
      font-weight: bold;
      font-size: 10px;
      line-height: inherit;
      cursor: pointer;
    }

    .ant-select-multiple .ant-select-selection-item-remove>* {
      line-height: 1;
    }

    .ant-select-multiple .ant-select-selection-item-remove svg {
      display: inline-block;
    }

    .ant-select-multiple .ant-select-selection-item-remove::before {
      display: none;
    }

    .ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
      display: block;
    }

    .ant-select-multiple .ant-select-selection-item-remove:hover {
      color: rgba(0, 0, 0, 0.75);
    }

    .ant-select-multiple .ant-select-selection-overflow-item+.ant-select-selection-overflow-item .ant-select-selection-search {
      -webkit-margin-start: 0;
      margin-inline-start: 0;
    }

    .ant-select-multiple .ant-select-selection-search {
      position: relative;
      max-width: 100%;
      margin-top: 2px;
      margin-bottom: 2px;
      -webkit-margin-start: 3px;
      margin-inline-start: 3px;
    }

    .ant-select-multiple .ant-select-selection-search-input,
    .ant-select-multiple .ant-select-selection-search-mirror {
      height: 24px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      line-height: 24px;
      transition: all 0.3s;
    }

    .ant-select-multiple .ant-select-selection-search-input {
      font-size: 14px;
      width: 100%;
      min-width: 4.1px;
    }

    .ant-select-multiple .ant-select-selection-search-mirror {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      white-space: pre;
      visibility: hidden;
    }

    .ant-select-multiple .ant-select-selection-placeholder {
      position: absolute;
      top: 50%;
      right: 11px;
      left: 8px;
      transform: translateY(-50%);
      transition: all 0.3s;
    }

    .ant-select-multiple.ant-select-lg .ant-select-selector::after {
      line-height: 32px;
    }

    .ant-select-multiple.ant-select-lg .ant-select-selection-item {
      height: 32px;
      line-height: 30px;
    }

    .ant-select-multiple.ant-select-lg .ant-select-selection-search {
      height: 32px;
      line-height: 32px;
    }

    .ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
    .ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
      height: 32px;
      line-height: 30px;
    }

    .ant-select-multiple.ant-select-sm .ant-select-selector::after {
      line-height: 16px;
    }

    .ant-select-multiple.ant-select-sm .ant-select-selection-item {
      height: 16px;
      line-height: 14px;
    }

    .ant-select-multiple.ant-select-sm .ant-select-selection-search {
      height: 16px;
      line-height: 16px;
    }

    .ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
    .ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
      height: 16px;
      line-height: 14px;
    }

    .ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
      left: 7px;
    }

    .ant-select-multiple.ant-select-sm .ant-select-selection-search {
      -webkit-margin-start: 3px;
      margin-inline-start: 3px;
    }

    .ant-select-multiple.ant-select-lg .ant-select-selection-item {
      height: 32px;
      line-height: 32px;
    }

    .ant-select-disabled .ant-select-selection-item-remove {
      display: none;
    }

    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      cursor: text;
    }

    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
      cursor: auto;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      // border-color: #0066FF;
      // border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      cursor: not-allowed;
    }

    .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: #f5f5f5;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
      cursor: not-allowed;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      margin: 0;
      padding: 0;
      background: transparent;
      border: none;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
      display: none;
      -webkit-appearance: none;
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #0066FF;
      border-right-width: 1px !important;
    }

    .ant-select-selection-item {
      flex: 1;
      overflow: hidden;
      // white-space: nowrap;
      text-overflow: ellipsis;
    }

    .ant-select-selection-placeholder {
      flex: 1;
      overflow: hidden;
      color: #bec5d2;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      pointer-events: none;
    }

    .ant-select-arrow {
      display: inline-block;
      font-style: normal;
      text-transform: none;
      vertical-align: -0.125em;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      top: 53%;
      right: 11px;
      width: 12px;
      height: 12px;
      margin-top: -6px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 14px;
      line-height: 1;
      text-align: center;
      pointer-events: none;
    }

    .ant-select-arrow>* {
      line-height: 1;
    }

    .ant-select-arrow svg {
      display: inline-block;
    }

    .ant-select-disabled .ant-select-arrow {
      cursor: not-allowed;
    }
  }

}