/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.team-quota-container {
  padding-left: 8px;
  padding-right: 8px;
  border-left: 1px solid #E8E9EA;
}
.team-quota-container .quota-btn {
  display: none;
}
.team-quota-container-hover:hover .quota-detail {
  display: none;
}
.team-quota-container-hover:hover .quota-btn {
  display: block;
  margin-top: -4px;
  width: 90px;
}
.quota-detail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-indent: 18px;
  height: 14px;
  background: url('../assets/disk.svg') no-repeat left;
  background-size: 14px 14px;
}
.quota-detail .quota-img {
  width: 16px;
  height: 16px;
}
.quota-detail .quota-word {
  font-size: 12px;
  color: #6A707C;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
}
.quota-detail .red-word {
  color: #FF563B;
}
.quota-detail-tooltip-inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.quota-detail-tooltip-inner .tooltip-inner-front {
  font-size: 12;
  color: #fff;
}
.quota-detail-tooltip-inner .tooltip-inner-end {
  font-size: 12;
  color: #0066ff;
  cursor: pointer;
}
.space-quota-modal .team-quota-hint {
  font-size: 14px;
  color: #8a93a8;
}
.space-quota-modal .team-quota-hint .team-quota-hint-num {
  font-size: 14px;
  color: #0066ff;
}
.space-quota-modal .ant-confirm-btns button {
  padding: 0;
}
.space-quota-modal .icon-local-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
}
