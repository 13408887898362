/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.create-team-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.create-team-content-left {
  width: 500px;
  position: relative;
  padding: 32px 0;
  height: 100%;
}
.create-team-content-left .title {
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  font-weight: normal;
  color: #222A35;
  height: 26px;
  line-height: 26px;
  margin-bottom: 16px;
  padding-left: 32px;
}
.create-team-content-left .create-team-content-left-body {
  padding: 0 32px;
  height: 380px;
  overflow: hidden;
  overflow-y: auto;
}
.create-team-content-left .team-name > p,
.create-team-content-left .team-type > p,
.create-team-content-left .team-permis > p,
.create-team-content-left .team-space-type > p,
.create-team-content-left .team-add-member > p {
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  font-family: PingFangSC-Medium;
  height: 22px;
  margin-bottom: 8px;
}
.create-team-content-left .team-name {
  margin-bottom: 18px;
  height: 76px;
}
.create-team-content-left .team-name > span {
  margin-top: 2px;
  color: #FF563B;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.create-team-content-left .team-name .cuowu-tip {
  font-size: 14px;
  padding-right: 2px;
}
.create-team-content-left .team-name .tips {
  display: flex;
  align-items: center;
  color: #FF563B;
  font-size: 12px;
}
.create-team-content-left .team-name .tips .tips-left {
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.create-team-content-left .team-name .tips a {
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 4px;
  color: #06f;
}
.create-team-content-left .team-name .icon-error {
  font-size: 14px;
}
.create-team-content-left .team-name .team-name-input {
  border-radius: 4px !important;
  padding: 2px 11px !important;
}
.create-team-content-left .team-name .team-name-input:hover {
  border-color: #1A6EFF !important;
}
.create-team-content-left .team-name .team-name-input :global .ant-input-suffix {
  visibility: hidden;
}
.create-team-content-left .team-name .team-name-input :global input.ant-input:focus {
  border: none !important;
}
.create-team-content-left .team-name .team-name-input:hover :global .ant-input-suffix,
.create-team-content-left .team-name .team-name-input:focus-within :global .ant-input-suffix {
  visibility: visible;
}
.create-team-content-left .team-type {
  margin-bottom: 20px;
}
.create-team-content-left .team-type > div > div {
  margin-bottom: 6px;
  line-height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 22px;
}
.create-team-content-left .team-type > div > div .label {
  color: #222A35;
  margin-right: 8px;
}
.create-team-content-left .team-type > div > div .des {
  font-size: 12px;
  color: #6A707C;
}
.create-team-content-left .team-type > div .disabled {
  cursor: not-allowed;
}
.create-team-content-left .team-type > div .disabled .label {
  color: #909499;
}
.create-team-content-left .team-type > div .disabled :global .ant-radio-wrapper:hover .ant-radio-inner {
  border-color: rgba(34, 42, 53, 0.08) !important;
}
.create-team-content-left .team-space-type {
  margin-bottom: 20px;
}
.create-team-content-left .team-space-type-box {
  display: flex;
  justify-content: space-between;
}
.create-team-content-left .team-space-type-box > div {
  border: 1px solid rgba(34, 42, 53, 0.1);
  padding: 8px 13px;
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  width: 50%;
  cursor: pointer;
}
.create-team-content-left .team-space-type-box > div:last-child {
  margin-left: 10px;
}
.create-team-content-left .team-space-type-box > div > div {
  margin-left: 4px;
}
.create-team-content-left .team-space-type-box > div > div > span {
  display: block;
}
.create-team-content-left .team-space-type-box > div > div .label {
  font-size: 14px;
  color: #222A35;
}
.create-team-content-left .team-space-type-box > div > div .des {
  line-height: 18px;
  color: #6A707C;
  font-size: 12px;
  word-break: break-all;
}
.create-team-content-left .team-space-type-box .div-active {
  border: 1px solid #1A6EFF;
}
.create-team-content-left .required > p {
  position: relative;
}
.create-team-content-left .required > p::after {
  content: '*';
  font-size: 16px;
  color: #ff3f5a;
  position: absolute;
  top: 2px;
  margin-left: 4px;
}
.create-team-content-left .team-permis {
  margin-bottom: 20px;
}
.create-team-content-left .team-permis :global .dropdown-checkbox {
  width: 100% !important;
  height: 36px !important;
}
.create-team-content-left .team-permis :global .dropdown-checkbox .dropdown-checkbox__value .dropdown-checkbox__caret {
  height: 36px !important;
}
.create-team-content-left .team-permis > p::after {
  content: '*';
  font-size: 16px;
  color: #ff3f5a;
  position: absolute;
  left: 87px;
  top: 2px;
}
.create-team-content-left .team-module > p {
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  font-family: PingFangSC-Medium;
  height: 22px;
  margin-bottom: 8px;
}
.create-team-content-left .team-module .team-module-content {
  margin-bottom: 16px;
}
.create-team-content-left .team-module .team-module-content :global .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.create-team-content-left .team-module .team-module-content :global .ant-checkbox-wrapper {
  margin-bottom: 6px;
}
.create-team-content-left .team-module-item-label {
  color: rgba(0, 0, 0, 0.9);
  margin: 0 8px 0 0;
}
.create-team-content-left .team-module-item-desc {
  font-size: 12px;
  color: #6A707C;
}
.create-team-content-left .team-add-member .ant-select {
  height: 36px !important;
}
.create-team-content-left .team-add-member :global input:focus {
  border: none !important;
}
.create-team-content-left .private {
  cursor: pointer;
}
.create-team-content-left .public {
  cursor: pointer;
}
.create-team-content .action {
  position: absolute;
  left: 32px;
  bottom: 32px;
}
.create-team-content .action .cancel {
  margin-right: 8px;
}
.create-team-content-right {
  width: 300px;
  height: 100%;
}
.modal-content > p {
  font-size: 14px;
  font-weight: 400;
  font-family: PingFang SC;
  line-height: 22px;
  color: #4E555D;
}
.modal-content > p:first-child {
  margin-bottom: 20px;
}
.modal-content > p span {
  cursor: pointer;
  color: #1A6EFF;
  font-weight: 500;
}
.modal-content > p span:hover {
  text-decoration: underline;
}
.permission-tip {
  width: 20px;
  height: 20px;
  line-height: 20px !important;
  text-align: center;
  color: rgba(34, 42, 53, 0.5);
  cursor: pointer;
  margin-left: 2px;
  font-size: 14px !important;
  display: inline-block;
}
.permission-tip:hover {
  color: #222A35;
  background-color: #E8E9EA;
  border-radius: 4px;
}
.out-team-space-details {
  color: #222A35;
  font-size: 14;
}
.out-team-space-details > span {
  color: #1A6EFF;
  cursor: pointer;
  font-weight: 500;
}
