/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.template-modal-content .modal-title {
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 11px;
  padding-bottom: 8px;
}
.template-modal-content .modal-title .modal-title-item {
  margin-right: 72px;
  text-align: center;
  color: #BBBBBB;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.template-modal-content .modal-title .modal-title-item .item-line {
  width: 24px;
  height: 2px;
  border-radius: 1px;
  margin-top: 4px;
}
.template-modal-content .modal-title .modal-title-item .item-checked {
  background-color: #1A75FF;
}
.template-modal-content .modal-title .modal-title-item:last-child {
  margin-right: 0;
}
.template-modal-content .modal-title .modal-title-item-right {
  margin-right: 0;
}
.template-modal-content .modal-title .item-text-checked {
  color: #333333;
}
.template-modal-content .template-modal-main {
  display: flex;
  height: 443px;
  background: #F8F8F8;
  border-top: 1px solid #EFF1F3;
  border-radius: 8px;
}
.template-modal-content .template-modal-main .template-type-container {
  background: #ffffff;
  border-bottom-left-radius: 8px;
}
.template-modal-content .template-modal-main .template-type-container .template-type-item {
  width: 134px;
  padding: 10px;
  text-align: center;
  color: #333330;
  cursor: pointer;
}
.template-modal-content .template-modal-main .template-type-container .template-type-item .template-name {
  font-size: 14px;
  line-height: 24px;
}
.template-modal-content .template-modal-main .template-type-container .template-type-item-active {
  color: #1A75FF;
  font-weight: 600;
  background: rgba(26, 117, 255, 0.08);
}
.template-modal-content .template-modal-main .template-list-container {
  border-left: 1px solid #E6E6E6;
  background: #F8F8F8;
  padding: 20px 0 20px 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom-right-radius: 8px;
  flex: 1;
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main {
  display: flex;
  flex-wrap: wrap;
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main .one-template-container {
  margin-bottom: 22px;
  margin-right: 20px;
  text-align: center;
  width: 116px;
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main .one-template-container:nth-child(4n) {
  margin-right: 0;
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main .one-template-container .template-img-container {
  position: relative;
  padding: 10px;
  border: 0.5px solid #E8E8E8;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main .one-template-container .template-img-container:hover {
  box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.1);
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main .one-template-container .template-img-container:hover .hover-container {
  transform: scale(1.05, 1.05);
  transition-duration: 0.3s;
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main .one-template-container .template-img-container:hover .hover-button-wrap {
  opacity: 1;
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main .one-template-container .template-img-container .hover-container {
  font-size: 0;
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main .one-template-container .template-img-container .hover-container .hover-container-img {
  width: 100%;
  height: 79px;
  object-fit: cover;
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main .one-template-container .template-img-container .hover-container .hover-container-doc {
  height: 120px;
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main .one-template-container .template-img-container .hover-button-wrap {
  opacity: 0;
  background: #ffffff;
  position: absolute;
  bottom: 8px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.57) 22%, #FFFFFF 99%);
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main .one-template-container .template-img-container .hover-button-wrap .btn-hover {
  width: 84px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  background-color: #fff;
  cursor: pointer;
  outline: none;
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main .one-template-container .template-img-container .hover-button-wrap .btn-hover-use {
  margin-top: 4px;
  background-color: #333333;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.template-modal-content .template-modal-main .template-list-container .one-template-type-list .one-template-type-main .one-template-container .template-name {
  color: #666666;
  line-height: 17px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 8px 0 0 0;
}
.template-modal-content .template-moddal-iframe {
  padding: 20px;
}
.template-modal-content .template-moddal-iframe iframe {
  width: 660px;
  height: 100%;
  border: none;
}
.template-modal-content .empty-wrap {
  background: #F8F8F8;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.template-modal-content .empty-wrap .empty-img {
  width: 70px;
  height: 70px;
}
.template-modal-content .empty-wrap .empty-title {
  margin-top: 24px;
  color: #BBBBBB;
  font-size: 12px;
  line-height: 17px;
}
