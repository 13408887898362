/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.select-input-wrap {
  width: calc(100% - 24px);
}
.search-input-popover {
  width: 268px;
}
.dk-select-input-dropdown {
  width: 243px;
}
.select-input {
  height: 34px;
  border-radius: 6px;
}
.option-li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
  border-radius: 4px;
}
.option-li :global .node-name {
  display: inline-flex;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.option-li .check-icon {
  font-size: 16px;
  margin-right: 4px;
  color: #047FFE;
  margin-left: 4px;
}
.active {
  background-color: rgba(11, 131, 255, 0.08);
  color: #0b83ff;
}
.departmentTag {
  margin: 0 10px 0 0;
  width: 42px;
  height: 22px;
}
