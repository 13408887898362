/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-team-list {
  background: #fff;
  overflow: hidden;
  height: 100%;
}
.cooper-team-list .ctl-content {
  background: #fff;
  height: 100%;
}
.cooper-team-list .ctl-title {
  font-size: 28px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #333333;
  padding: 0px 24px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.cooper-team-list .ctl-title .cooper-subhead {
  flex: 1;
}
.cooper-team-list .ctl-title .ant-btn {
  color: #0066FF;
  border: 1px #0066FF solid;
  border-radius: 4px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cooper-team-list .ctl-title .ant-btn img {
  width: 16px;
  height: 16px;
}
.cooper-team-list .ctl-title .ant-btn span {
  font-size: 14px;
  color: #0066FF;
  margin-left: 2px;
  font-weight: 400;
}
.cooper-team-list .ctl-title .ant-btn img {
  width: 16px;
  height: 16px;
}
.cooper-team-list .ctl-title .ant-btn span {
  font-size: 14px;
  color: #0066FF;
}
.cooper-team-list .ctl-empty-tip {
  overflow: hidden;
  width: 100%;
  position: relative;
  top: 30%;
}
.cooper-team-list .ctl-empty-tip .ctl-et-img {
  margin: 0 auto;
  display: block;
  width: 180px;
}
.cooper-team-list .ctl-empty-tip .ctl-et-text {
  margin: 12px 0 0;
  padding: 0;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(34, 42, 53, 0.7);
}
.cooper-team-list .ctl-empty-tip .ctl-et-link {
  color: #047FFE;
  cursor: pointer;
}
.cooper-team-list .ctl-empty-tip-owner {
  margin: 48px 0 80px 0;
}
.cooper-team-list .ctl-empty-tip-owner .ctl-et-img {
  width: 140px;
}
.cooper-team-list .ctl-box {
  width: 100%;
  padding: 0 32px;
}
.cooper-team-list .ctl-box .ctl-box-trigger {
  position: relative;
  float: right;
  margin-right: 20px;
  padding-right: 16px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #0066FF;
  cursor: pointer;
}
.cooper-team-list .ctl-box .ctl-box-trigger .ctl-box-trigger-icon {
  position: absolute;
  right: 0;
  top: 7px;
  width: 8px;
  transform: rotate(180deg);
}
.cooper-team-list .ctl-box .ctl-box-trigger.open .ctl-box-trigger-icon {
  top: 6px;
  transform: rotate(0);
}
.cooper-team-list .ctl-box .ctl-box-title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  color: #222A35;
  font-family: PingFangSC-Medium;
  margin-bottom: 16px;
}
.cooper-team-list .ctl-box .ctl-box-list {
  width: 100%;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(332px, 1fr));
  padding-bottom: 32px;
}
.cooper-team-list .ctl-box-item {
  position: relative;
  display: inline-block;
  border-radius: 8px;
  padding: 16px;
  height: 92px;
  cursor: pointer;
  background: #F6F7F7;
  border: 1px solid #F6F7F7;
}
.cooper-team-list .ctl-box-item.ctl-box-item-add {
  float: left;
  padding-top: 48px;
  line-height: 12px;
  font-size: 12px;
  text-align: center;
  color: #bec5d2;
  background-color: rgba(230, 231, 237, 0.2);
  border-style: dashed;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center 17px;
  background-image: url('./icon/icon_jiahao.svg');
}
.cooper-team-list .ctl-box-item.ctl-box-item-add:hover {
  border-color: #0066FF;
  box-shadow: none;
}
.cooper-team-list .ctl-box-item:hover {
  border: 1px solid #1A6EFF;
  background: #FFFFFF;
}
.cooper-team-list .ctl-box-item:hover .ctl-bi-ding {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cooper-team-list .ctl-box-item:hover .ctl-bi-ding :global(.dk-iconfont) {
  color: #222A35 !important;
}
.cooper-team-list .ctl-box-item .f1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.cooper-team-list .ctl-box-item .ctl-bi-ding {
  display: none;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
}
.cooper-team-list .ctl-box-item .ctl-bi-ding:hover {
  background: #F2F3F3;
}
.cooper-team-list .ctl-box-item .ctl-bi-ding.ctl-bi-dinged :global(.dk-iconfont) {
  color: #BDC0C3;
}
.cooper-team-list .ctl-box-item .ctl-bi-ding.ctl-bi-dinged,
.cooper-team-list .ctl-box-item .ctl-bi-ding.ctl-bi-dinged:hover {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cooper-team-list .ctl-box-item .ctl-bi-logo {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}
.cooper-team-list .ctl-box-item .ctl-bi-logo-default {
  border-radius: 2px;
  margin-right: 8px;
  width: 24px;
  height: 24px;
}
.cooper-team-list .ctl-box-item .ctl-bi-name {
  font-size: 14px;
  line-height: 20px;
  color: #333;
  display: flex;
  padding-right: 24px;
  font-family: PingFangSC-Medium;
  overflow: hidden;
}
.cooper-team-list .ctl-box-item .ctl-bi-name :global .tag-global {
  margin-left: 0px;
  margin-right: 4px;
}
.cooper-team-list .ctl-box-item .ctl-bi-name .f1-text {
  display: inline-block;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cooper-team-list .ctl-box-item .ctl-bi-name .department {
  background: #F7DA9C;
  color: #6D4800;
  font-size: 12px;
  border-radius: 2px;
  display: inline-block;
  height: 18px;
  line-height: 18px;
  font-weight: 700;
  padding: 0 4px;
  box-sizing: content-box;
  margin-right: 8px;
  transform: scale(0.916);
}
.cooper-team-list .ctl-box-item .ctl-bi-name .department:last-child {
  margin-left: 8px;
  margin-right: 0;
}
.cooper-team-list .ctl-box-item .ctl-bi-info {
  margin-top: 16px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  color: #6A707C;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.cooper-team-list .ctl-box-item .ctl-bi-info .quota-word {
  max-width: 60px;
}
.cooper-team-list .ctl-box-item .ctl-bi-info .ctl-bi-info-status {
  cursor: pointer;
  color: #1A6EFF;
}
.cooper-team-list .ctl-box-item .ctl-bi-info-out {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.cooper-team-list .ctl-box-item .ctl-bi-info-out-status-box {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cooper-team-list .ctl-box-item .ctl-bi-info-out-status-box .ctl-bi-info-out-status {
  display: flex;
  align-items: center;
  padding: 3px 8px;
  border-radius: 12px;
  align-content: center;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 12px;
  height: 24px;
}
.cooper-team-list .ctl-box-item .ctl-bi-info-out-status-box .ctl-bi-info-out-status > span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cooper-team-list .ctl-box-item .ctl-bi-info-out-status-box .ctl-bi-info-out-status .status-cion {
  font-size: 14px;
  margin-right: 4px;
}
.cooper-team-list .ctl-box-item .ctl-bi-info-out-status-box .ctl-bi-info-out-status-reject {
  color: #FA652F;
  background: rgba(250, 101, 47, 0.1);
}
.cooper-team-list .ctl-box-item .ctl-bi-info-out-status-box .ctl-bi-info-out-status-applied {
  color: #1A6EFF;
  background: rgba(26, 110, 255, 0.102);
}
.cooper-team-list .ctl-box-item .ctl-bi-info-out-action-box {
  display: flex;
}
.cooper-team-list .ctl-box-item .ctl-bi-info-out-action-box > span {
  font-size: 13px;
  cursor: pointer;
  padding: 4px 12px;
  background: #FFFFFF;
  margin: 0 3px;
  border-radius: 4px;
  color: rgba(34, 42, 53, 0.9);
}
.cooper-team-list .ctl-box-item .ctl-bi-info-out-action-box > span:hover {
  color: #222A35;
}
.cooper-team-list .ctl-box-item .ctl-bi-info-private,
.cooper-team-list .ctl-box-item .ctl-bi-info-public {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 14px;
  font-size: 12px;
  text-indent: 18px;
  padding-right: 8px;
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: 0 0;
  word-break: keep-all;
}
.cooper-team-list .ctl-box-item .ctl-bi-info-private {
  background-image: url('./icon/icon_simi.svg');
  background-size: 14px 14px;
}
.cooper-team-list .ctl-box-item .ctl-bi-info-public {
  background-image: url('./icon/icon_gongkai.svg');
  background-size: 14px 14px;
}
.cooper-team-list .ctl-box-item .ctl-bi-info-member {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 14px;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 12px;
  text-indent: 18px;
  background-size: 14px 14px;
  background: url('./icon/icon_chengyuan.svg') no-repeat left;
  background-origin: content-box;
  border-left: 1px solid #E8E9EA;
  word-break: keep-all;
}
.cooper-team-list .ctl-box-item .clt-bi-info-owner {
  padding-right: 8px;
  padding-left: 8px;
  border-left: 1px solid #E8E9EA;
  font-size: 12px;
  text-indent: 18px;
  background-size: 14px 14px;
  background: url('./icon/owner-icon.svg') no-repeat left;
  background-origin: content-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cooper-team-list .ctl-box-item-out {
  cursor: auto;
}
.cooper-team-list .ctl-box-item-out:hover {
  background: #F6F7F7;
  border: 1px solid #F6F7F7;
}
.loading-div {
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 10;
}
:global .windows .cooper-team-list-os-flag {
  overflow: hidden;
}
:global .windows .cooper-team-list-os-flag:hover {
  overflow-y: overlay;
}
:global .windows .cooper-team-list-os-flag .ctl-content-os-flag {
  width: 100%;
}
:global .windows .cooper-team-list-os-flag .ctl-content-os-flag:hover {
  width: -moz-calc(117%) !important;
  width: -o-calc(117%) !important;
  width: -webkit-calc(117%) !important;
  width: calc(100% + 17px) !important;
}
:global .mac .ctl-content-os-flag,
:global .linux .ctl-content-os-flag,
:global .generic .ctl-content-os-flag {
  overflow-y: overlay;
}
