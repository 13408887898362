/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.item-wrap {
  margin-bottom: 2px;
}
.item-wrap p:last-child span:nth-child(2) {
  min-width: 96px;
  white-space: nowrap;
}
.item-wrap p:last-child span:first-child {
  max-width: 80%;
}
.item-wrap:last-child {
  margin-bottom: 0px;
}
:global #search-result-list {
  height: 100%;
  overflow-y: hidden;
}
:global .mac #search-result-list:hover {
  overflow-y: auto;
}
.search-page-result {
  padding: 2px 15px 0px 12px;
  position: relative;
  flex: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.search-page-result :global .history-title {
  padding-top: 20px !important;
  font-size: 12px !important;
  line-height: 20px !important;
}
.search-page-result .search-page-total {
  padding-top: 20px;
  font-size: 12px;
  line-height: 20px;
  color: rgba(34, 42, 53, 0.5);
  position: relative;
  padding-left: 14px;
}
.search-page-result :global .search-page-empty .icon {
  width: 120px;
}
.search-page-result :global .search-page-empty .title {
  margin-top: 16px;
}
.search-empty {
  font-size: 13px;
  color: #aaa;
  text-align: center;
  margin-top: 12px;
}
@media (max-width: 1016px) {
  .search-item > *:nth-child(2) {
    display: none;
  }
}
@media (max-width: 840px) {
  .search-item > *:last-child {
    display: none;
  }
}
@media (max-width: 680px) {
  .search-item > *:nth-child(3) {
    display: none;
  }
}
/** scrollbar for windows,mac and others**/
:global .windows .search-page-result-os-flag {
  overflow: hidden;
}
:global .windows .search-page-result-os-flag:hover {
  overflow-y: auto;
}
:global .windows .search-page-result-os-flag .search-page-content-os-flag {
  overflow: hidden;
}
:global .windows .search-page-result-os-flag .search-page-content-os-flag:hover {
  overflow-y: auto;
  width: -moz-calc(117%) !important;
  width: -o-calc(117%) !important;
  width: -webkit-calc(117%) !important;
  width: calc(100% + 17px) !important;
}
:global .mac .search-page-result-os-flag,
:global .linux .search-page-result-os-flag,
:global .generic .search-page-result-os-flag {
  overflow-y: auto;
  overflow-y: overlay;
}
:global .mac .search-page-content-os-flag,
:global .linux .search-page-content-os-flag,
:global .generic .search-page-content-os-flag {
  overflow-y: auto;
  overflow-y: overlay;
}
