/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.user-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 8px;
}
.user-item .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
