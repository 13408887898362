// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.create-team-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  &-left {
    width: 500px;
    position: relative;
    padding: 32px 0;
    height: 100%;

    .title {
      font-family: PingFangSC-Semibold;
      font-size: 18px;
      font-weight: normal;
      color: @blueGray-color;
      height: 26px;
      line-height: 26px;
      margin-bottom: 16px;
      padding-left: 32px;
    }

    .create-team-content-left-body {
      padding: 0 32px;
      height: 380px;
      overflow: hidden;
      overflow-y: auto;
    }

    .team-name,
    .team-type,
    .team-permis,
    .team-space-type,
    .team-add-member {
      >p {
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        font-family: PingFangSC-Medium;
        height: 22px;
        margin-bottom: 8px;
      }
    }

    .team-name {
      margin-bottom: 18px;
      height: 76px;

      >span {
        margin-top: 2px;
        color: #FF563B;
        font-size: 12px;
        display: flex;
        align-items: center;
      }

      .cuowu-tip {
        font-size: 14px;
        padding-right: 2px;
      }

      .tips {
        display: flex;
        align-items: center;
        color: #FF563B;
        font-size: 12px;

        .tips-left {
          max-width: 180px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }


        a {
          max-width: 180px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-left: 4px;
          color: #06f,
        }
      }

      .icon-error {
        font-size: 14px;
      }

      .team-name-input {
        border-radius: 4px !important;
        padding: 2px 11px !important;
        &:hover {
          border-color: #1A6EFF !important;
        }
        :global {
          .ant-input-suffix {
            visibility: hidden;
          }

          input.ant-input:focus {
            border: none !important
          }
        }

        &:hover,
        &:focus-within {
          :global {
            .ant-input-suffix {
              visibility: visible;
            }
          }
        }
      }
    }

    .team-type {

      margin-bottom: 20px;

      >div {
        >div {
          margin-bottom: 6px;
          line-height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular;
          display: block;
          overflow: hidden;
          width: 100%;
          height: 22px;
  
          .label {
            color: @blueGray-color;
            margin-right: 8px;
          }
  
          .des {
            font-size: 12px;
            color: #6A707C;
          }
        }
        .disabled {
          cursor: not-allowed;
          .label {
            color: #909499;
          }
          :global {
            .ant-radio-wrapper:hover {
              .ant-radio-inner {
                border-color: rgba(34, 42, 53, 0.08) !important;
              }
            }
          }
        }
      }
    }
    .team-space-type {
      margin-bottom: 20px;
      &-box {
        display: flex;
        justify-content: space-between;
        >div {
          border: 1px solid @blueGray-10;
          padding: 8px 13px;
          display: flex;
          align-items: flex-start;
          border-radius: 4px;
          width: 50%;
          cursor: pointer;
          &:last-child{
            margin-left: 10px;
          }
          >div {
            margin-left: 4px;
            >span {
              display: block;
            }
            .label {
              font-size: 14px;
              color: @blueGray-1;
            }
            .des {
              line-height: 18px;
              color: @blueGray-15;
              font-size: 12px;
              word-break:break-all
            }
          }
        }
        .div-active {
          border: 1px solid #1A6EFF;
        }
      }
    }
    .required {
      >p {
        position: relative;

        &::after {
          content: '*';
          font-size: 16px;
          color: #ff3f5a;
          position: absolute;
          // left: 60px;
          top: 2px;
          margin-left: 4px;
        }
      }

    }

    .team-permis {
      margin-bottom: 20px;

      :global {
        .dropdown-checkbox {
          width: 100% !important;
          height: 36px !important;
          .dropdown-checkbox__value .dropdown-checkbox__caret {
            height: 36px !important;
          }
        }

      }

      >p {
        &::after {
          content: '*';
          font-size: 16px;
          color: #ff3f5a;
          position: absolute;
          left: 87px;
          top: 2px;
        }
      }
    }

    .team-module {
      >p {
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        font-family: PingFangSC-Medium;
        height: 22px;
        margin-bottom: 8px
      }

      .team-module-content {
        margin-bottom: 16px;

        :global {
          .ant-checkbox-wrapper+.ant-checkbox-wrapper {
            margin-left: 0;
          }

          .ant-checkbox-wrapper {
            margin-bottom: 6px;
          }
        }
      }
      
      &-item-label {
        color: rgba(0, 0, 0, 0.9);
        margin: 0 8px 0 0;
      }

      &-item-desc {
        font-size: 12px;
        color: @blueGray-15
      }
    }

    .team-add-member {
      .ant-select {
        height: 36px !important;
      }
      :global {
        input {
          &:focus {
            border: none !important;
          }
        }
      }
    }

    .private {
      cursor: pointer;
    }

    .public {
      cursor: pointer;
    }
  }

  .action {
    position: absolute;
    left: 32px;
    bottom: 32px;

    .cancel {
      margin-right: 8px;
    }
  }

  &-right {
    width: 300px;
    height: 100%;
  }

}
.modal-content {
  >p {
    font-size: 14px;
    font-weight: 400;
    font-family: PingFang SC;
    line-height: 22px;
    color: #4E555D;
    &:first-child {
      margin-bottom: 20px;
    }
    span {
      cursor: pointer;
      color: #1A6EFF;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }

  }
}
.permission-tip {
  width: 20px;
  height: 20px;
  line-height: 20px !important;
  text-align: center;
  color: @blueGray-6;
  cursor: pointer;
  margin-left: 2px;
  font-size: 14px !important;
  display: inline-block;

  &:hover {
    color: @blueGray-1;
    background-color: #E8E9EA;
    border-radius: 4px;
  }
}

.out-team-space-details {
  color: #222A35;
  font-size: 14;
  >span {
    color: #1A6EFF;
    cursor: pointer;
    font-weight: 500;
  }
}